import React, { useState, useEffect } from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

import LazyAnimationIndex from '../components/lazeeAnimationIndex'

import "../styles/styles.css"
import "../styles/fontStyles.css"

import Button from "react-bootstrap/Button"
import "bootstrap/dist/css/bootstrap.min.css"

import leaf from "../images/Vicenzas-MapleLeaf.png"

// import P5 from "../components/p5"

import Head from "../components/head"

// The commented out block of code below was meant to debounce or throttle the number of true and false results given by the browser when the window was resized. It turned out to not be needed. It was left in for future reference.

// function debounce(fn, ms) {
//   let timer
//   return _ => {
//     clearTimeout(timer)
//     timer = setTimeout(_ => {
//       timer = null
//       fn.apply(this, arguments)
//     }, ms)
//   };
// }

const ArtDirectedBackground = () => {
  const { portrait, landscape } = useStaticQuery(
    graphql`
      query { 
        portrait: file(relativePath: {eq: "Easter-2025-Portrait.jpg"}) {
          childImageSharp {
            gatsbyImageData(
              width: 1000, 
              placeholder: BLURRED,
              quality: 50, 
              webpOptions: {quality: 70}
            )
          }
        }
        landscape: file(relativePath: {eq: "Easter-2025-2.jpg"}) {
          childImageSharp {
            gatsbyImageData(
              width: 2000, 
              placeholder: BLURRED,
              quality: 50, 
              webpOptions: {quality: 70}
            )
          }
        }
      }
    `
  )

  const sources = [
    {
      ...getImage(portrait),
      media: `screen and (orientation: portrait)`,
    },
    {
      ...getImage(landscape),
      media: `screen and (orientation: landscape)`,
    }]

  // The state and useEffect below are to handle the resizing of the window which is what is used to detect when a hand held device is flipped from portrait to landscape and vice-versa. The background image will change between portrait and landscape when the device's orientation changes. Please see the className prop in the <BackgroundImage> component below.

  // const [flip, setFlip] = useState(false)

  // useEffect(() => {
  //   function handleResize() {
  //     setFlip(!flip) 
  //   }
  //   window.addEventListener('resize', handleResize)
  //   return _ => {
  //     window.removeEventListener('resize', handleResize)
  //   }
  // })

    // Initialize state without relying on the `window` object
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    // Define a function to update the state based on the window size
    const handleResize = () => {
      setIsPortrait(window.innerHeight > window.innerWidth);
    };

    // Set the initial state correctly when the component mounts
    handleResize();

    // Add window resize listener
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    //The function below is the countdown timer to when customers can begin ordering for Christmas 2021.

    function getTimeRemaining() {
      const total = Date.parse("Nov 1, 2022") - Date.parse(new Date())
      const days = Math.ceil(total / (1000 * 60 * 60 * 24))
      // const hours = Math.ceil((total / (1000 * 60 * 60)) % 24)
  
      return {
        days,
      }
    }
  
    //The if statement below is to adjust the grammar of the sentence viewers see so that day of ordering is "today" not "Monday"
  
    let timeDays
  
    if (getTimeRemaining().days !== 0) {
      timeDays = "Tuesday"
    } else {
      timeDays = "today"
    }
 
  return (
    <LazyAnimationIndex>
    <BgImage
      style={{backgroundPosition: `50% 100%`}} image={sources}
      className={`${isPortrait ? 'landscape' : 'portrait'} ${isPortrait ? 'portrait' : 'landscape'}`}
    >

    {/* ********* Can't get p5.js to work ************* */}
        {/* <div
            style={{ margin: "0", padding: "0", height: "100vh", bottom: "-1vh" }}
            >
            <P5 className="sketch"></P5>
        </div> */}
    {/* *********************************************** */}

      <Head title="Home" />
          <div
            style={{position: `relative`, width: `100vw`, height: `100vh`}}
            >
            {/* <P5></P5> */}
              <h1 className={`title ${isPortrait ? '' : 'animation'}`}>Vicenza's Crostoli</h1>
              <h2 className={`sub-title ${isPortrait ? '' : 'animation'}`}>Easter 2025</h2> 

              <div className={`animation canadian ${isPortrait ? 'canadianPortrait' : 'canadianLandscape'}`}>
                  <span>Proudly Canadian!</span>
                  <img className={`animation`} src={leaf} alt="Maple Leaf" style={{ verticalAlign: 'middle', width: isPortrait ? '5vh' : '3.5vw', height: 'auto' }} />
              </div>

          </div>
          {/* <Link to="/orderPage"> */}
              <Button variant="default" size="lg" className={`main-button ${isPortrait ? '' : 'animation'}`}>
                {/* Ordering begins {timeDays} at 9am */}
                Ordering begins April 7th
                {/* Order Crostoli */}
              </Button>{" "}
          {/* </Link> */}
    </BgImage>
    </LazyAnimationIndex>
  )
}



export default ArtDirectedBackground







